var render = function () {
  var _vm$mealPlans$meta, _vm$mealPlans$meta2, _vm$mealPlans, _vm$mealPlans$meta3, _vm$mealPlans$meta4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "w": "100%"
    }
  }, [_c('c-text', [_vm._v("Manajemen Klien")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "my": "10px"
    }
  }), _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-size": "2xl"
    }
  }, [_vm._v(" Manajemen Klien ")]), _vm.role === 'super_admin' ? _c('c-button', {
    attrs: {
      "h": "auto",
      "py": "12px",
      "px": "28px",
      "color": "#927102",
      "border-radius": "24px",
      "border-color": "transparent",
      "bg": "secondary.800",
      "variant-color": "inherit",
      "font-size": "16px",
      "line-height": "24px",
      "variant": "solid"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add-client');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-plus.svg'),
      "alt": "icon",
      "display": "inline-block",
      "mr": "10px"
    }
  }), _vm._v(" Tambah Klien ")], 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "18px",
      "margin-top": "6px",
      "background-color": "secondary.50"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "background-color": "warning.200",
      "min-height": "70px",
      "padding": "16px",
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" Daftar Panduan Makan ")]), _c('c-flex', {
    attrs: {
      "gap": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "height": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "height": "24px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "100%",
      "placeholder": "Search",
      "border-radius": "20px",
      "padding-left": "50px",
      "position": "relative",
      "z-index": "1",
      "border": "1px solid #C4C4C4"
    },
    on: {
      "keyup": _vm.onSearchMealPlan
    },
    model: {
      value: _vm.filterSearchMealPlan,
      callback: function callback($$v) {
        _vm.filterSearchMealPlan = $$v;
      },
      expression: "filterSearchMealPlan"
    }
  })], 1)], 1), _c('BaseMenu', {
    attrs: {
      "trigger": "manual"
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn(_ref) {
        var toggle = _ref.toggle;
        return [_c('c-button', {
          attrs: {
            "variant-color": "gray",
            "variant": "outline",
            "color": "neutral.black",
            "background-color": "neutral.superLightGray",
            "font-size": "16px",
            "font-weight": "400",
            "min-height": "50px",
            "border-radius": "20px"
          },
          on: {
            "click": toggle
          }
        }, [_vm._v(" Filter "), _c('c-box', {
          attrs: {
            "margin-left": "16px"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-filter.svg'),
            "height": "13",
            "width": "13",
            "fill": "#555555"
          }
        })], 1)], 1)];
      }
    }, {
      key: "menu",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('c-box', {
          attrs: {
            "z-index": "10",
            "min-width": "300px",
            "px": "24px",
            "padding-bottom": "12px",
            "mt": "24px"
          }
        }, [_c('c-box', {
          attrs: {
            "text-align": "start"
          }
        }, [_c('BaseInputSelect', {
          attrs: {
            "placeholder": "Pilih Status",
            "label": "Status",
            "size": "sm",
            "options": [{
              value: 'review',
              label: 'Menunggu Review'
            }, {
              value: 'revisi',
              label: 'Sedang Direvisi'
            }, {
              value: 'sent',
              label: 'Diterima Klien'
            }, {
              value: 'read',
              label: 'Sudah dibuka Klien'
            }]
          },
          model: {
            value: _vm.filterStatusMealPlan,
            callback: function callback($$v) {
              _vm.filterStatusMealPlan = $$v;
            },
            expression: "filterStatusMealPlan"
          }
        })], 1), _c('c-menu-divider'), _c('c-flex', {
          attrs: {
            "justify": "flex-end",
            "padding": "0 8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "border-radius": "1000px",
            "size": "small",
            "padding": "0 24px"
          },
          on: {
            "click": function click() {
              close();

              _vm.applyFilterMealPlans();
            }
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      }
    }])
  })], 1)], 1), _c('BaseTable', {
    attrs: {
      "color": "secondary",
      "columns": _vm.mealPlans.columns,
      "items": _vm.mealPlans.items,
      "page": (_vm$mealPlans$meta = _vm.mealPlans.meta) === null || _vm$mealPlans$meta === void 0 ? void 0 : _vm$mealPlans$meta.page,
      "per-page": (_vm$mealPlans$meta2 = _vm.mealPlans.meta) === null || _vm$mealPlans$meta2 === void 0 ? void 0 : _vm$mealPlans$meta2.pagesize,
      "total-page": _vm.calculatePerPage((_vm$mealPlans = _vm.mealPlans) === null || _vm$mealPlans === void 0 ? void 0 : _vm$mealPlans.total, (_vm$mealPlans$meta3 = _vm.mealPlans.meta) === null || _vm$mealPlans$meta3 === void 0 ? void 0 : _vm$mealPlans$meta3.pagesize),
      "sort": (_vm$mealPlans$meta4 = _vm.mealPlans.meta) === null || _vm$mealPlans$meta4 === void 0 ? void 0 : _vm$mealPlans$meta4.sort
    },
    on: {
      "on-change-page": function onChangePage(page_) {
        return _vm.onChangePage('MEAL_PLANS', page_);
      }
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('ChipStatus2', {
          style: {
            width: '170px'
          },
          attrs: {
            "status": item === null || item === void 0 ? void 0 : item.status,
            "screen": "mobile-only"
          }
        })];
      }
    }, {
      key: "aksi",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('c-button', {
          attrs: {
            "background-color": "white",
            "border-radius": "16px",
            "padding": "8px 16px",
            "font-size": "14px",
            "border": "2px solid #008C81",
            "font-weight": "medium",
            "color": "primary.400"
          },
          on: {
            "click": function click($event) {
              return _vm.toDetailPage(item);
            }
          }
        }, [_vm._v(" Lihat ")])];
      }
    }])
  })], 1), _c('c-box', {
    attrs: {
      "mt": "30px",
      "w": "100%",
      "border": "1px solid #f2f2f2",
      "box-shadow": "4px 4px 50px 5px #0000000D",
      "rounded": "lg",
      "overflow": "hidden"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "px": "20px",
      "h": "75px"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1"
    }
  }, [_vm._v(" Daftar Klien ")]), _c('c-stack', {
    attrs: {
      "is-inline": "",
      "spacing": "10px"
    }
  }, [_vm.role === 'super_admin' ? _c('c-button', {
    attrs: {
      "h": "40px",
      "rounded": "full",
      "color": "gray.700",
      "variant": "outline",
      "variant-color": "gray",
      "font-size": "14px",
      "font-weight": "400",
      "type": "button",
      "as": "a",
      "href": _vm.exportUrl,
      "download": "clients.xls"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-file-export.svg'),
      "alt": "icon",
      "display": "inline-block",
      "mr": "10px"
    }
  }), _vm._v(" Export ")], 1) : _vm._e(), _c('ManagementClientFilterBtn', {
    attrs: {
      "filters": _vm.filters
    },
    on: {
      "change": function change($event) {
        _vm.status = $event;
      }
    }
  })], 1), _c('c-box', {
    attrs: {
      "border-left": "1px solid #f2f2f2",
      "h": "70%",
      "w": "2px",
      "mx": "20px"
    }
  }), _c('c-input-group', {
    attrs: {
      "bg": "#F2F2F2",
      "rounded": "md"
    }
  }, [_c('c-input-left-addon', {
    attrs: {
      "bg": "none",
      "border": "none"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg')
    }
  })], 1), _c('c-input', {
    attrs: {
      "bg": "none",
      "border": "none",
      "size": "sm",
      "px": "0",
      "placeholder": "Pencarian",
      "_placeholder": {
        color: '#555',
        opacity: 1
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('ManagementClientTable', {
    attrs: {
      "clients": _vm.clients,
      "rows": _vm.rows,
      "page": _vm.page
    },
    on: {
      "detail": function detail($event) {
        return _vm.$emit('detail', $event);
      }
    }
  }), _c('c-flex', {
    attrs: {
      "h": "85px",
      "align-items": "center",
      "px": "40px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex": "1",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "#888",
      "font-weight": "400",
      "font-size": "14px"
    }
  }, [_vm._v(" Show : ")]), _c('c-input-group', [_c('c-select', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "appearance": "none",
      "d": "inline-block",
      "rounded": "md",
      "ml": "10px"
    },
    model: {
      value: _vm.rows,
      callback: function callback($$v) {
        _vm.rows = $$v;
      },
      expression: "rows"
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 baris ")]), _c('option', {
    attrs: {
      "value": "30"
    }
  }, [_vm._v(" 30 baris ")]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v(" 50 baris ")])])], 1)], 1), _c('c-flex', {
    attrs: {
      "justify-content": "flex-end"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "type": "button",
      "p": "0",
      "mr": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.page === 1,
      "as": "router-link",
      "to": {
        query: Object.assign({}, _vm.$route.query, {
          page: _vm.page - 1
        })
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  })], 1), _vm._l(_vm.pages, function (p, index) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            bg: 'rgba(0, 140, 129, 0.3)',
            color: 'primary.400'
          }
        },
        expression: "{\n              ':hover': {\n                bg: 'rgba(0, 140, 129, 0.3)',\n                color: 'primary.400',\n              },\n            }"
      }],
      key: index,
      attrs: {
        "p": "0",
        "m": "0",
        "w": "32px",
        "h": "32px",
        "min-w": "32px",
        "mx": "4px",
        "border-radius": "30px",
        "background-color": "rgba(0, 140, 129, 0.3)",
        "color": "primary.400",
        "bg": p === _vm.page ? ['rgba(0, 140, 129, 0.3)'] : ['transparent'],
        "is-disabled": isNaN(+p),
        "as": "router-link",
        "to": {
          name: _vm.$route.name,
          query: Object.assign({}, _vm.$route.query, {
            page: p
          })
        }
      }
    }, [_vm._v(" " + _vm._s(p) + " ")]);
  }), _c('c-button', {
    attrs: {
      "p": "0",
      "ml": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.pages.length === _vm.page,
      "as": "router-link",
      "to": {
        query: Object.assign({}, _vm.$route.query, {
          page: _vm.page + 1
        })
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }