<template>
  <c-flex
    flex-dir="column"
    w="100%"
  >
    <c-text>Manajemen Klien</c-text>
    <hr
      v-chakra
      my="10px"
    >

    <c-flex align-items="center">
      <c-text
        flex="1"
        font-size="2xl"
      >
        Manajemen Klien
      </c-text>
      <c-button
        v-if="role === 'super_admin'"
        h="auto"
        py="12px"
        px="28px"
        color="#927102"
        border-radius="24px"
        border-color="transparent"
        bg="secondary.800"
        variant-color="inherit"
        font-size="16px"
        line-height="24px"
        variant="solid"
        @click="$emit('add-client')"
      >
        <c-image
          :src="require('@/assets/icon-plus.svg')"
          alt="icon"
          display="inline-block"
          mr="10px"
        />
        Tambah Klien
      </c-button>
    </c-flex>

    <c-box
      display="flex"
      flex-direction="column" 
      gap="18px"
      margin-top="6px"
      background-color="secondary.50"
    >
      <c-box
        display="flex"
        justify-content="space-between"
        align-items="center"
        background-color="warning.200"
        min-height="70px"
        padding="16px"
        border-top-left-radius="8px"
        border-top-right-radius="8px"
      >
        <BaseText
          size-mobile="16px"
          size-desktop="18px"
        >
          Daftar Panduan Makan
        </BaseText>
        <c-flex gap="16px">
          <c-box width="100%">
            <c-form-control
              pos="relative"
              w="100%"
              height="100%"
            >
              <c-image
                :src="require('@/assets/ic-search.svg')"
                alt="icon"
                position="absolute"
                top="50%"
                transform="translateY(-50%)"
                left="15px"
                height="24px"
                z-index="2"
              />
              <c-input
                id="search"
                v-model="filterSearchMealPlan"
                type="text"
                w="100%"
                height="100%"
                placeholder="Search"
                border-radius="20px"
                padding-left="50px"
                position="relative"
                z-index="1"
                border="1px solid #C4C4C4"
                @keyup="onSearchMealPlan"
              />
            </c-form-control>
          </c-box>

          <BaseMenu trigger="manual">
            <template #button="{ toggle }">
              <c-button
                variant-color="gray"
                variant="outline"
                color="neutral.black"
                background-color="neutral.superLightGray"
                font-size="16px"
                font-weight="400"
                min-height="50px"
                border-radius="20px"
                @click="toggle"
              >
                Filter
                <c-box margin-left="16px">
                  <inline-svg
                    :src="require('@/assets/icons/icon-filter.svg')"
                    height="13"
                    width="13"
                    fill="#555555"
                  />
                </c-box>
              </c-button>
            </template>
            <template #menu="{ close }">
              <c-box
                z-index="10"
                min-width="300px"
                px="24px"
                padding-bottom="12px"
                mt="24px"
              >
                <c-box text-align="start">
                  <BaseInputSelect
                    v-model="filterStatusMealPlan"
                    placeholder="Pilih Status"
                    label="Status"
                    size="sm"
                    :options="[
                      { value: 'review', label: 'Menunggu Review' },
                      { value: 'revisi', label: 'Sedang Direvisi' },
                      { value: 'sent', label: 'Diterima Klien' },
                      { value: 'read', label: 'Sudah dibuka Klien' },
                    ]"
                  />
                </c-box>
                <c-menu-divider />
                <c-flex
                  justify="flex-end"
                  padding="0 8px"
                >
                  <BaseButton
                    border-radius="1000px"
                    size="small"
                    padding="0 24px"
                    @click="() => {
                      close()
                      applyFilterMealPlans()
                    }"
                  >
                    Simpan
                  </BaseButton>
                </c-flex>
              </c-box>
            </template>
          </BaseMenu>

          <!--              <c-flex align="center">-->
          <!--                <BaseText-->
          <!--                  size-mobile="14px-2"-->
          <!--                  size-desktop="16px"-->
          <!--                >-->
          <!--                  Show-->
          <!--                </BaseText>-->
          <!--                <c-select-->
          <!--                  min-width="140px"-->
          <!--                  border-radius="8px"-->
          <!--                  size="md"-->
          <!--                  color="primary.400"-->
          <!--                  font-size="18px"-->
          <!--                  font-weight="500"-->
          <!--                  margin-left="16px"-->
          <!--                  :value="clientTracking.meta?.pagesize?.toString()"-->
          <!--                  @change="(perPage) => onChangePerPage('CLIENTS_TRACKING', +perPage)"-->
          <!--                >-->
          <!--                  <option value="5">-->
          <!--                    5 Rows-->
          <!--                  </option>-->
          <!--                  <option value="10">-->
          <!--                    10 Rows-->
          <!--                  </option>-->
          <!--                  <option value="15">-->
          <!--                    15 Rows-->
          <!--                  </option>-->
          <!--                  <option value="20">-->
          <!--                    20 Rows-->
          <!--                  </option>-->
          <!--                </c-select>-->
          <!--              </c-flex>-->
        </c-flex>
      </c-box>
      <BaseTable
        color="secondary"
        :columns="mealPlans.columns"
        :items="mealPlans.items"
        :page="mealPlans.meta?.page"
        :per-page="mealPlans.meta?.pagesize"
        :total-page="calculatePerPage(mealPlans?.total, mealPlans.meta?.pagesize)"
        :sort="mealPlans.meta?.sort"
        @on-change-page="(page_) => onChangePage('MEAL_PLANS', page_)"
      >
        <!--        @on-change-sort="(sort) => onChangeSort('MEAL_PLANS', sort)"-->
        <template #status="{ item }">
          <ChipStatus2
            :style="{ width: '170px' }"
            :status="item?.status"
            screen="mobile-only"
          />
        </template>
        <template #aksi="{ item }">
          <c-button
            background-color="white"
            border-radius="16px"
            padding="8px 16px"
            font-size="14px"
            border="2px solid #008C81"
            font-weight="medium"
            color="primary.400"
            @click="toDetailPage(item)"
          >
            Lihat
          </c-button>
        </template>
      </BaseTable>
    </c-box>

    <c-box
      mt="30px"
      w="100%"
      border="1px solid #f2f2f2"
      box-shadow="4px 4px 50px 5px #0000000D"
      rounded="lg"
      overflow="hidden"
    >
      <c-flex
        align-items="center"
        px="20px"
        h="75px"
      >
        <c-text flex="1">
          Daftar Klien
        </c-text>
        <c-stack
          is-inline
          spacing="10px"
        >
          <c-button
            v-if="role === 'super_admin'"
            h="40px"
            rounded="full"
            color="gray.700"
            variant="outline"
            variant-color="gray"
            font-size="14px"
            font-weight="400"
            type="button"
            as="a"
            :href="exportUrl"
            download="clients.xls"
          >
            <c-image
              :src="require('@/assets/icon-file-export.svg')"
              alt="icon"
              display="inline-block"
              mr="10px"
            />
            Export
          </c-button>
          <ManagementClientFilterBtn
            :filters="filters"
            @change="status = $event"
          />
        </c-stack>

        <c-box
          border-left="1px solid #f2f2f2"
          h="70%"
          w="2px"
          mx="20px"
        />
        <c-input-group
          bg="#F2F2F2"
          rounded="md"
        >
          <c-input-left-addon
            bg="none"
            border="none"
          >
            <c-image :src="require('@/assets/ic-search.svg')" />
          </c-input-left-addon>
          <c-input
            v-model="search"
            bg="none"
            border="none"
            size="sm"
            px="0"
            placeholder="Pencarian"
            :_placeholder="{
              color: '#555',
              opacity: 1,
            }"
          />
        </c-input-group>
      </c-flex>

      <ManagementClientTable
        :clients="clients"
        :rows="rows"
        :page="page"
        @detail="$emit('detail', $event)"
      />

      <c-flex
        h="85px"
        align-items="center"
        px="40px"
      >
        <c-flex
          flex="1"
          align-items="center"
        >
          <c-text
            color="#888"
            font-weight="400"
            font-size="14px"
          >
            Show :
          </c-text>
          <c-input-group>
            <c-select
              v-model="rows"
              v-chakra
              appearance="none"
              d="inline-block"
              rounded="md"
              ml="10px"
            >
              <option value="10">
                10 baris
              </option>
              <option value="30">
                30 baris
              </option>
              <option value="50">
                50 baris
              </option>
            </c-select>
          </c-input-group>
        </c-flex>

        <c-flex justify-content="flex-end">
          <c-flex
            justify-content="center"
            align="center"
          >
            <c-button
              type="button"
              p="0"
              mr="16px"
              w="32px"
              h="32px"
              min-w="32px"
              variant="outline"
              border-radius="30px"
              background-color="transparent"
              :is-disabled="page === 1"
              as="router-link"
              :to="{ query: { ...$route.query, page: page - 1 } }"
            >
              <c-icon
                name="chevron-left"
                size="24px"
              />
            </c-button>

            <c-button
              v-for="(p, index) in pages"
              :key="index"
              v-chakra="{
                ':hover': {
                  bg: 'rgba(0, 140, 129, 0.3)',
                  color: 'primary.400',
                },
              }"
              p="0"
              m="0"
              w="32px"
              h="32px"
              min-w="32px"
              mx="4px"
              border-radius="30px"
              background-color="rgba(0, 140, 129, 0.3)"
              color="primary.400"
              :bg="p === page ? ['rgba(0, 140, 129, 0.3)'] : ['transparent']"
              :is-disabled="isNaN(+p)"
              as="router-link"
              :to="{ name: $route.name, query: { ...$route.query, page: p } }"
            >
              {{ p }}
            </c-button>

            <c-button
              p="0"
              ml="16px"
              w="32px"
              h="32px"
              min-w="32px"
              variant="outline"
              border-radius="30px"
              background-color="transparent"
              :is-disabled="pages.length === page"
              as="router-link"
              :to="{ query: { ...$route.query, page: page + 1 } }"
            >
              <c-icon
                name="chevron-right"
                size="24px"
              />
            </c-button>
          </c-flex>
        </c-flex>
      </c-flex>
    </c-box>
  </c-flex>
</template>

<script>
import ManagementClientFilterBtn from '@/components/managements/clients/filter-button'
import { calculatePaginations } from '@/utils/calculate-paginations'
import ManagementClientTable from '@/components/managements/clients/table'
import BaseTable from '@/components/elements/base-table.vue'
import BaseMenu from '@/components/elements/base-menu.vue'
import BaseInputSelect from '@/components/elements/base-input-select.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { formatDateV2 } from '@/utils/format-date'
import _ from 'lodash'
import { mapActions } from 'vuex'
import ChipStatus2 from '@/components/elements/chip-status-2.vue'

export default {
  name: 'ManagementClientsIndex',
  components: {
    ChipStatus2,
    BaseButton,
    BaseText,
    BaseInputSelect,
    BaseMenu,
    BaseTable, ManagementClientTable, ManagementClientFilterBtn },
  props: ['title', 'filters', 'clients', 'exportUrl', 'role', 'totalData'],
  data() {
    return {
      filterStatusMealPlan: '',
      filterSearchMealPlan: '',
      timerFilterSearchMealPlan: null,
      mealPlans: {
        columns: [{
          id: 'client.fullName',
          label: 'Nama Klien',
          type: 'TEXT',
          customRender: (item) => {
            return item?.client?.fullName || '-'
          },
        }, {
          id: 'clientManager.fullName',
          label: 'Ahli Gizi',
          type: 'TEXT',
          customRender: (item) => {
            return item?.program?.nutritionist?.fullName || '-'
          },
        }, {
          id: 'createdAt',
          label: 'Dikirim pada',
          type: 'TEXT',
          customRender: (item) => {
            return item?.createdAt ? formatDateV2(item?.createdAt, 8) : '-'
          },
        }, {
          id: 'status',
          label: 'Status',
          type: 'CUSTOM2',
        }, {
          id: 'aksi',
          label: 'Aksi',
          type: 'CUSTOM2',
        }],
        items: [],
        meta: {
          page: 1,
          pagesize: 5,
          filter: 0,
        },
        total: 0,
      },
    }
  },
  computed: {
    page: {
      get() {
        return +(this.$route.query.page ?? 1)
      },
      set(v) {
        if (+this.$route.query.page === v) return
        this.$router.replace({
          query: { ...this.$route.query, page: v },
        })
      },
    },
    search: {
      get() {
        return this.$route.query.q
      },
      set(v) {
        if (this.$route.query.q === v) return
        this.$router.replace({ query: { ...this.$route.query, q: v } })
      },
    },
    rows: {
      get() {
        return this.$route.query.rows ?? '10'
      },
      set(v) {
        if (this.$route.query.rows === v) return
        this.$router.replace({
          query: {
            ...this.$router.query,
            rows: v,
          },
        })
      },
    },
    status: {
      get() {
        return this.$route.query.status
      },
      set(v) {
        if (this.$route.query.status === v) return
        this.$router.replace({ query: { ...this.$route.query, status: v } })
      },
    },
    pages() {
      return calculatePaginations({
        perPage: +this.rows,
        totalData: this.totalData,
        currentPage: this.page,
      })
    },
  },
  watch: {
    'mealPlans.meta': {
      handler: _.debounce(async function(newVal) {
        const res = await this.loadMealPlans({
          page: newVal.page,
          perpage: newVal.pagesize,
        })
        this.mealPlans.items = res.data?.result
        this.mealPlans.total = res.meta.total
      }, 800),
      deep: true,
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      loadMealPlans: 'admDashboard/listMealPlans',
    }),
    onSearchMealPlan() {
      if (this.timerFilterSearchMealPlan) {
        clearTimeout(this.timerFilterSearchMealPlan)
        this.timerFilterSearchMealPlan = null
      }
      this.timerFilterSearchMealPlan = setTimeout(() => {
        this.applyFilterMealPlans()
      }, 1000)
    },
    async applyFilterMealPlans() {
      this.$router.replace({
        name: 'admin.clients',
        query: {
          ...this.$route.query,
          meal_plans_q: this.filterSearchMealPlan,
          meal_plans_status: this.filterStatusMealPlan,
        },
        params: { savePosition: true },
      })
      this.mealPlans.meta.page = 1
      const res = await this.loadMealPlans({
        page: this.mealPlans.meta.page,
        perpage: this.mealPlans.meta.pagesize,
        q: this.filterSearchMealPlan,
        filter: this.filterStatusMealPlan,
      })
      this.mealPlans.items = res.data?.result
      this.mealPlans.total = res.meta.total
    },
    onChangePerPage(type, perPage) {
      switch (type) {
        case 'MEAL_PLANS':
          this.mealPlans.meta.pagesize = perPage
          this.mealPlans.meta.page = 1
          break
        default:
          break
      }
    },
    onChangePage(type, page) {
      switch (type) {
        case 'MEAL_PLANS':
          this.mealPlans.meta.page = page
          break
        default:
          break
      }
    },
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    async init(){
      const [res6] = await Promise.all([

        this.loadMealPlans({
          page: this.mealPlans.meta.page,
          perpage: this.mealPlans.meta.pagesize,
        }),
      ])

      this.mealPlans.items = res6.data?.result
      this.mealPlans.total = res6.meta.total
    },
    toDetailPage(item) {
      this.$router.push({
        name: 'admin.clients.meal-plan.profile',
        params: {
          clientId: item?.clientId,
          programId: item?.programId,
          month: item?.month,
        },
      })
    },
  },
}
</script>
