var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!!_vm.getData ? _c('c-box', {
    attrs: {
      "background-color": _vm.getData.backgroundColor,
      "border": "2px solid",
      "border-color": _vm.getData.borderColor,
      "color": _vm.getData.color,
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "border-radius": "1000px",
      "white-space": "nowrap",
      "padding": "8px 16px",
      "font-size": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getData.label) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }