<template>
  <div>
    <c-box
      v-if="!!getData"
      :background-color="getData.backgroundColor"
      border="2px solid"
      :border-color="getData.borderColor"
      :color="getData.color"
      display="flex"
      justify-content="center"
      align-items="center"
      border-radius="1000px"
      white-space="nowrap"
      padding="8px 16px"
      font-size="16px"
    >
      {{ getData.label }}
    </c-box>
  </div>
</template>

<script>

export default {
  name: 'ChipStatus2',
  props: {
    status: {
      type: String,
      default: '',
      validator(value) {
        return [
          'review',
          'revisi',
          'sent',
        ].includes(value)
      },
    },
  },
  computed: {
    getData() {
      switch (this.status) {
        case 'review':
          return {
            color: 'warning.400',
            borderColor: 'warning.400',
            backgroundColor: 'warning.50',
            label: 'Menunggu review',
          }
        case 'revisi':
          return {
            color: 'warning.400',
            borderColor: 'warning.400',
            backgroundColor: 'warning.50',
            label: 'Sedang direvisi',
          }
        case 'sent':
          return {
            color: 'info.400',
            borderColor: 'info.400',
            backgroundColor: 'info.50',
            label: 'Diterima klien',
          }
        case 'read':
          return {
            color: 'primary.400',
            borderColor: 'primary.400',
            backgroundColor: 'primary.50',
            label: 'Sudah dibuka Klien',
          }
        default:
          return ''
      }
    },
  },
}
</script>
